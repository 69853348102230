/*--------------------------------------------*\
    Include Globally Useful JS Functions
\*--------------------------------------------*/

ThemeJS.Functions = (function ( $, root ) {

    function send_ajax_request ( args ) {
        args.data.nonce = ThemeJS.Variables.Ajax.nonce;
        $.post( ThemeJS.Variables.Ajax.url, args.data, function ( response ) {
            response = JSON.parse( response );
            if ( !response.hasOwnProperty( 'data' ) ) { response.data = {}; }
            if ( response.success && typeof args.success == 'function' ) {
                args.success( response.data );
            } else if ( !response.success && typeof args.error == 'function' ) {
                args.error( response.data );
            }
        });
    }

    function get_query_parameter( name, url ) {
        if (!url) url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&#]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
    
    return {
        send_ajax_request: send_ajax_request,
        get_query_parameter: get_query_parameter
    }

})(jQuery, this);
// get cookie function
function getCookie(cname) { let name = cname + "="; let decodedCookie = decodeURIComponent(document.cookie); let ca = decodedCookie.split(';'); for(let i = 0; i <ca.length; i++) { let c = ca[i]; while (c.charAt(0) == ' ') { c = c.substring(1); } if (c.indexOf(name) == 0) { return c.substring(name.length, c.length); } } return ""; }

// =====================HEADER============================
// function stickyScroll() {
//     if (window.scrollY > 0) {
//         document.querySelector('#sticky-notification').style.position = "absolute";
//         document.querySelector('#sticky-notification').style.top = `${window.innerHeight - 60}px`;
//     } else {
//         document.querySelector('#sticky-notification').style.position = "initial";
//         document.querySelector('#sticky-notification').style.top = "0";
//     }
// }


//sub-menu arrow down 
function addToggleSubMenu() {
    document.querySelectorAll('.navigation-primary .menu-item-has-children').forEach(function (el) {
        let arrowDown = document.createElement('span');
        arrowDown.className = 'arrow-dropdown';
        arrowDown.innerHTML = '<i class="fad fa-angle-down"></i>';
        el.children[1].style.display = 'none'
        arrowDown.addEventListener('click', function () {
            el.children[2].style.display = el.children[2].style.display ==='none'?'block':'none'
            // console.log(el.children[1].style.display);
            // jQuery(el.children[1]).slideToggle();
        })
        arrowDown.addEventListener('click', function () {
            arrowDown.innerHTML = arrowDown.innerHTML === '<i class="fad fa-angle-down"></i>' ? '<i class="fad fa-angle-up"></i>' : '<i class="fad fa-angle-down"></i>';
        })
        jQuery(arrowDown).insertAfter(el.children[0]);
    })
}
function removeToggleSubMenu() {
    document.querySelectorAll('.navigation-primary .menu-item-has-children .arrow-dropdown').forEach(function (el) {
        el.remove()
    })
}

document.querySelector('.mobile-menu-hamburger').addEventListener('click', function () {
    jQuery('#hamburger-icon').toggleClass('open');
    jQuery('.navigation-box').slideToggle();
})

    // popup window close (global)
if (getCookie('isPopup') === 'false') { document.querySelector('#sticky-notification').style.display = 'none' }
if (document.querySelector('#sticky-notification button')) {
    document.querySelector('#sticky-notification button').addEventListener('click', function (el) {
        document.cookie = "isPopup=false; path=/";
        document.querySelector('#sticky-notification').style.display = 'none'
    })
}

// trim only first 2 letters of languages selector {
document.querySelectorAll('.wpml-ls-menu-item a span').forEach(function (el) {el.innerHTML=el.innerHTML.slice(0,2)        })

jQuery('#searchForm').appendTo('.navigation-secondary .menu')
jQuery('#searchForm i').click(function(){
    jQuery('#searchForm form').fadeToggle();
});
jQuery('#searchForm form').hide();

// document.addEventListener("scroll", function () { stickyScroll()});
// stickyScroll()

// =================================================================


// faq
if (document.querySelector('.faq')) {
    // toggle + -
    document.querySelectorAll('.faq .answer-container').forEach(function (button) {
        button.addEventListener("click", function (e) {
            jQuery(this.children[2]).slideToggle();
            this.children[0].innerHTML = this.children[0].innerHTML==='<i class="fas fa-plus"></i>'?'<i class="fas fa-minus"></i>':'<i class="fas fa-plus"></i>'
        })
    })
    // mobile section select
    jQuery('#faqSelectSections').click(function () {
        jQuery('.sections-container').slideToggle();
        jQuery('#faq-ad')[0].innerHTML
        jQuery('#faq-ad')[0].innerHTML = jQuery('#faq-ad')[0].innerHTML==='<i class="fad fa-angle-down"></i>' ? '<i class="fad fa-angle-up"></i>' : '<i class="fad fa-angle-down"></i>';
    })

    // Select first element on startup
    document.querySelectorAll('.faq .answers-container .answers').forEach(function (hideEl, count) {
        hideEl.style.display = 'none'
        if (count === 0) {hideEl.style.display = 'block'}
    })
    document.querySelectorAll('.faq .section-select')[0].classList.add('active-section')
    jQuery('#faqSections-currentText').text(jQuery('.active-section').text())

    // on section select
    for (let el = 0; el < document.querySelectorAll('.faq .section-select').length; el++) {
        let element = document.querySelectorAll('.faq .section-select')[el];
        element.addEventListener('click', function (e) {
            document.querySelectorAll('.faq .answers-container .answers').forEach(function (hideEl) {
                hideEl.style.display = 'none'
            })
            document.querySelectorAll('.faq .section-select').forEach(function (removeClass) {
                removeClass.classList.remove('active-section');
            })
            element.classList.add('active-section');
            document.querySelectorAll('.faq .answers-container .answers')[el].style.display = 'block'
            // set select button text on mobile
            jQuery('#faqSections-currentText').text(element.innerText)
        })
        
    }
}

// circkles info panel. Similar to faq
if (document.querySelector('.circles_info_panel')) {
    // Select first element on startup
    document.querySelectorAll('.circles_info_panel .points-content .point-content').forEach(function (hideEl, count) {
        hideEl.style.display = 'none'
        if (count === 0) {hideEl.style.display = 'block'}
    })
    // hide all but first triangle
    document.querySelectorAll('.circles_info_panel .points-content .triangle').forEach(function (hideEl, count) {
        hideEl.style.display = 'none';
        if (count === 0) {hideEl.style.display = 'block';}
    })
    document.querySelectorAll('.circles_info_panel .points-headers .point')[0].classList.add('point-active')

    for (let el = 0; el < document.querySelectorAll('.circles_info_panel .points-headers .point').length; el++) {
        let element = document.querySelectorAll('.circles_info_panel .points-headers .point')[el];
        let triangle = document.querySelectorAll('.circles_info_panel .points-content .triangle')[el];
        element.addEventListener('click', function (e) {
            // hide all answers, triangles and remove active classes
            document.querySelectorAll('.circles_info_panel .points-content .point-content').forEach(function (hideEl) {
                hideEl.style.display = 'none'
                triangle.style.display = 'none'
            })
            document.querySelectorAll('.circles_info_panel .points-content .triangle').forEach(function (hideEl) {
                hideEl.style.display = 'none'
            })
            document.querySelectorAll('.circles_info_panel .points-headers .point').forEach(function (removeClass) {
                removeClass.classList.remove('point-active');
            })
            // activate selected 
            element.classList.add('point-active');
            triangle.style.display = 'block'
            document.querySelectorAll('.circles_info_panel .points-content .point-content')[el].style.display = 'block'
        })
        
    }
}

// two-column-accordion
if (document.querySelector('.two_column_accordion')) {
    document.querySelectorAll('.two_column_accordion .accordion-item').forEach(function (button) {
        button.addEventListener("click", function (e) {
            jQuery(this.children[2]).slideToggle();
            this.children[0].innerHTML = this.children[0].innerHTML==='<i class="fas fa-plus"></i>'?'<i class="fas fa-minus"></i>':'<i class="fas fa-plus"></i>'
        })
    })
}

// accordion-info
if (document.querySelector('.accordion_info')) {
    document.querySelectorAll('.accordion_info .accordion-item').forEach(function (button) {
        button.addEventListener("click", function (e) {
            jQuery(this.children[2]).slideToggle();
            this.children[0].innerHTML = this.children[0].innerHTML==='<i class="fas fa-plus"></i>'?'<i class="fas fa-minus"></i>':'<i class="fas fa-plus"></i>'
        })
    })
}

// teams
if (document.querySelector('.team_section')) {
    document.querySelectorAll('.bio-popup-button').forEach(function (button, order) {
        button.addEventListener("click", function (e) {
            document.querySelectorAll('.team_section .popup')[order].style.display = 'initial';
        })
    })
    document.querySelectorAll('.team_section .popup').forEach(function (popup) {
        // close button
        popup.children[0].children[0].addEventListener("click", function (e) {
                // this.parentNode.children[2].style.display = 'none';
            this.parentNode.parentNode.style.display = 'none';
        });
        // close on click outside of popup
        popup.addEventListener("click", function (e) {
            if (e.target.classList[0] === 'popup') {
                this.parentNode.children[2].style.display = 'none';
            }
        })
    })   
}

