ThemeJS.Media_Queries = ( function ( $ ) {

    let breakpoints_obj = ThemeJS.Variables.Screen_Sizes;

    // JS objects cannot be relied on for ordering
    // rebuild into array of objects and sort
    let breakpoints = [];
    $.each( breakpoints_obj, function ( size, px ) {
        breakpoints.push({ size, px });
    });
    breakpoints.sort( function( a, b ) {
        return a.px - b.px;
    });

    let queries = {};
    $.each( breakpoints, function ( index, obj ) {
        queries[ obj.size ] = window.matchMedia( `( min-width: ${ obj.px }px )` );
        queries[ obj.size ].addListener( breakpoint_changed );
    });

    let previous_size = null;
    let current_size;
    calculate_current_size();
    function calculate_current_size () {
        let max_matched_size = 'xs';
        $.each( queries, function ( size, query ) {
            if ( query.matches ) {
                max_matched_size = size;
                return;
            }
            return false;
        });
        current_size = max_matched_size;
    }

    function breakpoint_changed () {
        previous_size = current_size;
        calculate_current_size();
        $( window ).trigger( 'breakpoint_changed', [ current_size, previous_size ] );
    }

    return {
        get_current_size: function () {
            return current_size;
        },
        is_at_least: function ( size ) {
            return queries[ size ].matches;
        },
    };

})(jQuery);

// media querry 
function matchQuerry(q) {
    if (q.matches) { //if less
        document.querySelector('.navigation-primary .menu-item-has-children .sub-menu').style.display = 'none';
        addToggleSubMenu();
        jQuery('.navigation-box').hide()
        jQuery('#hamburger-icon').removeClass('open');

        // teams button position
        if (document.querySelector('.team_section')) {
            document.querySelectorAll('.bio-popup-button').forEach(function (button) {
                button.parentElement.parentElement.parentElement.append(button)
            })
        }

        // circkles info panel - move accordion under the circle
        // if (document.querySelector('.circles_info_panel')) {
        //     jQuery('.circles_info_panel .point-content').each(function(counter, answer) {
        //         jQuery(answer).insertAfter(jQuery('.points-headers .point')[counter])
        //     })
        // }
    } else {
        jQuery('.navigation-box').show()
        removeToggleSubMenu()
        document.querySelector('.navigation-primary .menu-item-has-children .sub-menu').style.display = '';

        // restore teams button position
        if (document.querySelector('.team_section')) {
            document.querySelectorAll('.bio-popup-button').forEach(function (button, num) {
                document.querySelectorAll('.team_section .overlay')[num].append(button)
            })
        }

        // circkles info panel - move accordion back
        // if (document.querySelector('.circles_info_panel')) {
        //     jQuery('.circles_info_panel .point-content').each(function(counter, answer) {
        //         jQuery('.points-content').append(answer)
        //     })
        // }
    }
  }
  
var q = window.matchMedia("(max-width: 991px)")
matchQuerry(q)
q.addListener(matchQuerry);

//400 px

function matchSmallQuerry(qs) {
    if (qs.matches) { //if less
        console.log('400');
        // circkles info panel - move accordion under the circle
        if (document.querySelector('.circles_info_panel')) {
            jQuery('.circles_info_panel .point-content').each(function(counter, answer) {
                jQuery(answer).insertAfter(jQuery('.points-headers .point')[counter])
            })
        }
    } else {
        // circkles info panel - move accordion back
        if (document.querySelector('.circles_info_panel')) {
            jQuery('.circles_info_panel .point-content').each(function(counter, answer) {
                jQuery('.points-content').append(answer)
            })
        }
    }
}

var qs = window.matchMedia("(max-width: 400px)")
matchSmallQuerry(qs)
qs.addListener(matchSmallQuerry);